<template>
	<div>
		<div class="b-container">
			<div class="b-wrapper-page">
				<dashboards :post-count="allStrokeDashboard"/>
				<div :class="{'b-filter': true, open: open}">
					<button @click="open=!open" class="b-filter__button">
						<iconSvg v-if="!open" class="b-icon b-icon--filter icon-filter icon"/>
						<span v-if="!open">Фильтр записей</span>


						<iconCloseSvg v-if="open" class="b-icon b-icon--close icon-close icon"/>
						<span v-if="open">Скрыть</span>
					</button>
					<form class="b-filter__hidden" onsubmit="return false">
						<div class="b-filter__form">
							<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search input-group">
									<!-- Наименование КР -->
								<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search">
									<input id="search-filter-namee" placeholder="Наименование КР" v-model="searchName" class="b-input__input-field b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search" type="search" name="search-filter-name" />
									<iconSearchSvg class="b-icon b-icon--table-search"/>
									<label class="b-input__label" for="search-filter">Название</label>
								</div>
								<!-- Заболевание -->
								<div class="b-select b-select--dual b-select--clinical b-select--type">
										<v-select placeholder="Заболевание" :filterable="false" @open="onOpen" @close="onClose" @search="query => search = query" :clearable="false" :closeOnSelect="false" multiple v-model="searchResult" :options="paginated" label="name">
												<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
																Поиск не дал результатов <em>{{ search }}</em>.
														</template>
												</template>

														<template #list-footer>
																<li ref="load" class="loader" v-show="hasNextPage">
																		Список прогружается...
																</li>
														</template>
										</v-select>
								</div>
								<!-- Код МКБ-10 -->
								<div class="b-select b-select--dual b-select--clinical b-select--type">
										<v-select placeholder="Код МКБ-10" :filterable="false" @open="onOpenMkb" @close="onCloseMkb" @search="query => searchMkb = query" :closeOnSelect="false" :clearable="false" multiple v-model="searchResultMkb" :options="paginatedMkb" label="code">
												<template v-slot:no-options="{ search, searching }">
														<template v-if="searching"> Поиск не дал результатов <em>{{ search }}</em>.</template>
												</template>
												<template #list-footer>
																<li ref="load" class="loader" v-show="hasNextPage">
																		Список прогружается...
																</li>
														</template>
										</v-select>
								</div>
							</div>
							<div class="select-group">
									<!-- Дата от -->
								<div class="b-input b-input--dual b-input--clinical b-input--filter-date">
									<input :max="toJSONLocal(dateTo ? dateTo : null)" id="dateFrom" type="date" name="name" v-model="dateFrom" placeholder="Дата от" class="date-from"/>
									<label class="b-input__label" for="id"></label>
								</div>
								<!-- Дата до -->
								<div class="b-input b-input--dual b-input--clinical b-input--filter-date">
									<input :min="toJSONLocal(dateFrom ? dateFrom : '1970-01-01')" :max="toJSONLocal()" id="dateTo" type="date" name="name" v-model="dateTo" placeholder="Дата до" class="date-from"/>
									<label class="b-input__label" for="id"></label>
								</div>
								<!-- Разработчик -->
								<div class="b-select b-select--dual b-select--clinical b-select--type">
										<v-select placeholder="Разработчик" :reduce="obj => obj.id" :closeOnSelect="false" :clearable="false" multiple v-model="searchResultDev" :options="сompaniesList" label="name">
												<template v-slot:no-options="{ search, searching }">
														<template v-if="searching"> Поиск не дал результатов <em>{{ search }}</em>.</template>
												</template>
										</v-select>
								</div>
								<!-- Возрастная группа -->
								<div class="b-select b-select--dual b-select--clinical b-select--age">
									<v-select placeholder="Возрастная группа" :reduce="obj => obj.id" :closeOnSelect="false" :clearable="false" multiple v-model="ageGroups" :options="ageGroupsList" label="name">
												<template v-slot:no-options="{ search, searching }">
														<template v-if="searching"> Поиск не дал результатов <em>{{ search }}</em>.</template>
												</template>
										</v-select>
								</div>
							</div>

							<button @click="searchFunc()" class="b-button b-button--fill">
								<span>Поиск</span>
							</button>
							<button @click="reset()" class="ml-2 b-button b-button--cancel">
								<span>Сбросить фильтр</span>
							</button>
						</div>
					</form>
				</div>

				<div class="b-table-list b-table-list--summary">
					<div class="b-table-list__top">
						<div class="b-table-list__left">
							<div class="b-table-list__all">Всего записей:<span>{{allStroke}}</span>
							</div>
							<div class="b-table-list__scroll b-table-list__scroll--filter js-horizontal-scroll">
								<ul class="b-table-list__list-record">
									<li class="b-table-list__item-record"><a style="text-transform:none" @click="filterStage(0)" :class="{'b-link':true,'b-link--record':true, 'active':clickFilter==0}" href="javascript:void(0);" title="Все">Все</a></li>
									<li class="b-table-list__item-record"><a style="text-transform:none" @click="filterStage(31)" :class="{'b-link':true,'b-link--record':true, 'active':clickFilter==31}" href="javascript:void(0);" title="Уведомления">На обсуждении</a></li>
									<li class="b-table-list__item-record"><a style="text-transform:none" @click="filterStage(32)" :class="{'b-link':true,'b-link--record':true, 'active':clickFilter==32}" href="javascript:void(0);" title="Заявления">Обсуждение завершено</a></li>
									<!-- <li class="b-table-list__item-record"><a style="text-transform:none" @click="filterStage(34)" :class="{'b-link':true,'b-link--record':true, 'active':clickFilter==34}" href="javascript:void(0);" title="Заявления">Отозван</a></li> -->
								</ul>
							</div>
						</div>
						<div class="b-table-list__right">
							<button @click="dialog = true" class="b-button b-button--sub">
								<span>Подписаться</span>
							</button>
						</div>
						<!-- <div class="b-table-list__right">
							<a @click="downloadReports" class="b-link b-link--report" href="javascript:void(0);" title="">
								<iconDownloadSvg class="b-icon b-icon--report icon-download"/>
								<span class="mobile">Отчет</span>
								<span style="text-transform:none" class="desktop">Получить отчет</span>
							</a>
						</div> -->
					</div>
					<div class="b-table-list__table">
						<div style="position:relative;min-height:300px;" class="b-table-list__scroll b-table-list__scroll--table js-horizontal-scroll">

							<div v-if="isLoading" class="ring-load">
								<RingLoader class="ring-load-loading" color="#04092a" :size="160"/>
							</div>
							<table>
								<thead>
									<tr>
										<th onselectstart="return false" onmousedown="return false" v-for="(item, index) in columns" :key="index">
											<span @click="sortFunc(item)" style="cursor:pointer">{{item.name}}</span>

											<div v-show="checkColumn == item.nameEng" class="b-table-list__arrow">
												<button v-if="item.sort" class="b-table-list__top-button">
													<iconSortupSvg class="b-icon b-icon--table-button icon-sort-up"/>
												</button>
												<button v-else class="b-table-list__bottom-button">
													<iconSortdownSvg class="b-icon b-icon--table-button icon-sort-up"/>
												</button>
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, ind) in list" :key="ind" @click="nextPageEV(row.id)">
										<td>
											<a href="javascript:void(0);" title>№{{row.number ? row.number : ''}}</a>
											<time>{{row.date ? new Date(row.date).toLocaleDateString() : ''}}</time>
										</td>
										<td>{{row.startPublicDiscussionDate ? new Date(row.startPublicDiscussionDate).toLocaleDateString() : '&mdash;'}}</td>
										<td>
											{{row.name ? row.name : '&mdash;'}}
										</td>
										<td>
											{{row.mkb ? row.mkb.name : ''}} {{`${row.ageGroups.length !== 0 ? '('+Array.prototype.map.call(row.ageGroups, function(item) { return item.name; }).join(",") +')' : ''}`}}
										</td>
										<td>
											<ul style="padding: 0">
												<li v-for="(item, indx) in row.mkbCodes"  :key="indx">
														<b>{{item.code}}</b> &mdash; {{item.name}}
												</li>
											</ul>
										</td>
										<td>
											{{row.commentCount}}
										</td>
										<td>
											<span>
												{{row.developer ? row.developer.name : '&mdash;'}}
											</span>
										</td>
										<td>
											<span>{{row.status ? row.status.name : ''}}</span>
											<!-- <time datetime="18.09.2019 13:56">18.09.2019 в 13:56</time> -->
										</td>
										<td>
											{{row.finalPublicDiscussionDate ? new Date(row.finalPublicDiscussionDate).toLocaleDateString() : '&mdash;'}}
										</td>
									</tr>
								</tbody>
							</table>


							<center v-if="list.length == 0 && !isLoading" style="text-align:center">
								<p style="font-size:19px">Данных нет...</p>
							</center>
						</div>
					</div>
				</div>
				<div v-if="!isLoading" class="b-wrapper-page__bottom">
					<div class="b-pagination">
						<ul class="b-pagination__list">
							<li class="b-pagination__item-prev">
								<a @click="prevPage()" :class="{'b-button': true, 'b-button--slider': true, 'b-button--disabled':pageNumber==0 }" href="javascript:void(0);" title="">
										<iconAngleleftSvg class="b-icon icon-angle-left"/>
								</a>
							</li>

								<li class="b-pagination__item">
										<span>{{resultCount > 0 ? pageNumber+1 : 0}} из {{resultCount}} стр</span>
								</li>

							<li class="b-pagination__item-next">
									<a @click="nextPage()" :class="{'b-button': true, 'b-button--slider': true, 'b-button--disabled':pageNumber >= resultCount-1 }" href="javascript:void(0);" title="">
										<iconAnglerightSvg class="b-icon icon-angle-left"/>
										</a>
							</li>
						</ul>
					</div>
					<div class="b-number-page">
						<div class="b-number-page__text">
							Показать кол-во строк
							<span></span>
						</div>
						<!-- <div class="b-number-page__text">
							из<span>{{allStroke}}</span>

						</div> -->
						<div class="b-number-page__text">
							<select @change="updateListEvent(size)" style="width:40px" v-model="size">
								<option :value="5">5</option>
								<option :value="10">10</option>
								<option :value="20">20</option>
								<option :value="30">30</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div :class="{ 'background-block': dialog }" class="background">
			<div :class="{ 'sub-window-pad': subObj.mkbIds.length > 1 || subObj.companyIds.length > 0 }" class="sub-window">
				<div style=" margin-top: 20px;">
					<h1>Подписка на уведомления</h1>
				</div>
				<form ref="sub-form" class="sub-form" action="">
					<div class="b-select b-select--dual b-select--clinical b-select--type">
							<v-select placeholder="Заболевание" :filterable="false" :closeOnSelect="false" :rules="[rules.requiredMultiple]" multiple @open="onOpen" @search="query => search = query" :reduce="obj => obj.id"  item-value="id" :clearable="false" v-model="subObj.mkbIds" :options="paginated" label="name">
									<template v-slot:no-options="{ search, searching }">
											<template v-if="searching">
													Поиск не дал результатов <em>{{ search }}</em>.
											</template>
									</template>

											<template #list-footer>
													<li ref="load" class="loader" v-show="hasNextPage">
															Список прогружается...
													</li>
											</template>
							</v-select>
					</div>
					<div class="b-select b-select--dual b-select--clinical b-select--type">
							<v-select placeholder="Автор" :reduce="obj => obj.id" :closeOnSelect="false" multiple :rules="[rules.requiredMultiple]" :clearable="false" v-model="subObj.companyIds" item-value="id" :options="сompaniesList"  label="name">
									<template v-slot:no-options="{ search, searching }">
											<template v-if="searching"> Поиск не дал результатов <em>{{ search }}</em>.</template>
									</template>
							</v-select>
					</div>
					<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search">
						<input style="padding-left: 20px!important;" id="search-filter-sub" required placeholder="E-mail" v-model="subObj.email" class="b-input__input-field b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search" type="search" name="search-filter-name" />
					</div>
					<span style="color: red;">{{ errors[0] }}</span>
					<!-- <label class="label-sub" for="disease">Заболевание</label>
					<select :rules="[rules.required]" v-model="subObj.disease" class="sub-input" name="" id="disease">
						<option style="height: 60px;" v-for="(item, index) in mkbList " :value="item.id" :key="index">{{ item.code }} {{ item.name }}</option>
					</select> -->
					<!-- <label class="label-sub" for="author">Автор</label>
					<select :rules="[rules.required]" v-model="subObj.author" class="sub-input" name="" id="author">
						<option v-for="(item, index) in сompaniesList" :value="item.id" :key="index">{{ item.name }}</option>
					</select> -->
					<!-- <label class="label-sub" for="email">E-mail</label>
					<input :rules="[rules.required]" v-model="subObj.email" class="sub-input" type="text" id="email"> -->
				</form>
				<div style="display: flex;">
					<button :disabled="saveLoad" @click="saveSub" style="margin-top: 30px;" class="b-button b-button--fill">
						<span>Сохранить</span>
					</button>
					<button @click="close" style="margin: 30px 0 0 5px;" class="b-button b-button--sub">
						<span>Отменить</span>
					</button>
				</div>

			</div>
		</div>
    		<snackbar position="top-center" ref="snackbar" baseSize="100px" :wrapClass="''" color="info" :holdTime="4000"/>
		</div>
</template>

<script>
import iconSvg from "../../assets/icons/svg/filter.svg"
import iconCloseSvg from "../../assets/icons/svg/close.svg"
import iconSearchSvg from "../../assets/icons/svg/search.svg"
import iconAngleDownSvg from "../../assets/icons/svg/angle-down.svg"
import iconAngleleftSvg from "../../assets/icons/svg/angle-left.svg"
import iconAnglerightSvg from "../../assets/icons/svg/angle-right.svg"
import iconDownloadSvg from "../../assets/icons/svg/download.svg"
import iconSortdownSvg from "../../assets/icons/svg/sort-down.svg"
import iconSortupSvg from "../../assets/icons/svg/sort-up.svg"
import iconEditSvg from "../../assets/icons/svg/edit.svg"
import iconEyeSvg from "../../assets/icons/svg/eye.svg"
import orderBy from 'lodash.orderby'

import { RingLoader } from '@saeris/vue-spinners'


import Dropdown from '../../components/Dropdown/index'
import Dashboards from '../../components/Dashboards/index'
import {mapGetters, mapActions} from 'vuex'

export default {
	name: 'listCr',
	components: { RingLoader, Dashboards, Dropdown, iconEditSvg, iconEyeSvg, iconSvg, iconSearchSvg, iconAngleDownSvg, iconCloseSvg, iconDownloadSvg, iconSortdownSvg, iconSortupSvg, iconAngleleftSvg, iconAnglerightSvg },
	computed: {
			...mapGetters({
				media: 'layout/getMedia',
				apiUrl: 'apiUrl',
				apiUrlPortal: 'apiUrlPortal',
			}),
			filtered () {
					return this.mkbList.filter(f => f.name.includes(this.search));
			},
			paginated () {
					return this.filtered.slice(0, this.limit);
			},
			hasNextPage () {
					return this.paginated.length < this.filtered.length;
			},

			filteredMkb () {
					return this.mkbList.filter(f => f.code.includes(this.searchMkb.toUpperCase()))
			},
			paginatedMkb () {
					return this.filteredMkb.slice(0, this.limitMkb);
			},
			hasNextPageMkb () {
					return this.paginatedMkb.length < this.filteredMkb.length;
			},
	},
	data: () => ({
		isSearch: false,
		isLoading: true,
		allStroke: 0,
		allStrokeDashboard: 0,
		clickFilter: 0,
		open: false,
		saveLoad: false,
		subObj: {
			mkbIds: [],
			companyIds: [],
			email: '',
		},
		checkColumn: 'number',
		searchName: null,
		options: ['1','2','3'],
		columns: [
			{name: 'Номер и дата', sort: false, nameEng: 'number', click: true},
			{name: 'Дата начала обсуждения', sort: false, nameEng: 'startPublicDiscussionDate', click: false},
			{name: 'Наименование КР', sort: false, nameEng: 'name', click: false},
			{name: 'Заболевание и возрастная группа', sort: false, nameEng: 'mkbName', click: false},
			{name: 'МКБ-10', sort: false, nameEng: 'mkbCode', click: false},
			{name: 'Кол-во предложений', sort: false, nameEng: 'commentCount', click: false},
			{name: 'Разработчик', sort: false, nameEng: 'developerName', click: false},
			{name: 'Статус', sort: false, nameEng: 'statusName', click: false},
			{name: 'Дата окончания обсуждения', sort: false, nameEng: 'npsDecisionId', click: false}
		],
		list: [],
		listFreeze: [],
		pageNumber: 0,
		сompaniesList: [],
		serviceTypes: [],
		ageGroups: [],
		statuses: [],
		dateFrom: null,
		dateTo: null,
		size: 30,
		resultCount: 0,
		mkbList: [],
		//поиск по названию
		observer: null,
		limit: 10,
		search: '',
		searchResult: [],
		//поиск по мкб названию
		observerMkb: null,
		limitMkb: 10,
		searchMkb: '',
		errors:[],
		searchResultMkb: [],
		searchResultDev: [],
		searchResultNpc: [],
		ageGroupsList: [],
		dialog: false,
		rules: {
			required: v => (!!v || v === 0 || v.length === 0) || 'Обязательно для заполнения!',
			requiredMultiple: (v) => v.length > 0 || 'Обязательно для заполнения.',
		},
	}),
	methods: {
		...mapActions({
			apiAll: 'api/apiAll'
		}),
		async onOpen () {
				if (this.hasNextPage) {
						await this.$nextTick();
						this.observer.observe(this.$refs.load)
				}
		},
		onClose () {
				this.observer.disconnect();
		},
		close(){
			this.dialog = false
			this.subObj = {
				mkbIds: [],
				companyIds: [],
				email: '',
			}
			this.errors = []
		},
		async infiniteScroll ([{isIntersecting, target}]) {
				if (isIntersecting) {
						const ul = target.offsetParent;
						const scrollTop = target.offsetParent.scrollTop;
						this.limit += 10;
						await this.$nextTick();
						ul.scrollTop = scrollTop;
				}
		},
		async onOpenMkb () {
				if (this.hasNextPageMkb) {
						await this.$nextTick();
						this.observerMkb.observe(this.$refs.load)
				}
		},
		onCloseMkb () {
				this.observerMkb.disconnect();
		},
		async infiniteScrollMkb ([{isIntersecting, target}]) {
				if (isIntersecting) {
						const ul = target.offsetParent;
						const scrollTop = target.offsetParent.scrollTop;
						this.limitMkb += 10;
						await this.$nextTick();
						ul.scrollTop = scrollTop;
				}
		},
		saveSub(){
			this.saveLoad = true
			this.api.post(`${this.apiUrlPortal}Projects/AddSubscription`, this.subObj)
			.then(res => {
				// if(this.subObj.mkbIds.length === 0 || this.subObj.companyIds.length === 0 || !this.subObj.email ){
				// 	this.errors.push(res.data.userMessage)
				// }
				this.saveLoad = false
				if(res.data.success){
					this.close()
					this.$refs.snackbar.info('Подписка успешно сохранена');
					console.log(res);
				}
				else{
					console.log('else');
					this.error = []
					this.errors.push(res.data.userMessage)
				}
		})
		},
		sortFunc(e) {
			//очищаем всем флаг сортировки
			this.columns.forEach(el => el.click = false)

			this.isLoading = true
			e.sort = !e.sort
			e.click = true
			this.checkColumn = e.nameEng

			this.apiMethods(
				{ columnName: e.nameEng, orderOperation: e.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true },
				this.clickFilter !== 0 ? this.clickFilter : null
			)
		},
		pageCount(count){
			this.resultCount = Math.ceil(count/this.size)
		},
		nextPage(){
			this.pageNumber++;


			let columnsItem = this.columns.find(f => f.click);
			this.apiMethods(
				{ columnName: columnsItem.name, orderOperation: columnsItem.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true },
				this.clickFilter !== 0 ? this.clickFilter : null
			)
		},
		prevPage(){
			this.pageNumber--;

			let columnsItem = this.columns.find(f => f.click);
			this.apiMethods(
				{ columnName: columnsItem.name, orderOperation: columnsItem.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true },
				this.clickFilter !== 0 ? this.clickFilter : null
			)
		},
		searchFunc() {
			this.pageNumber = 0
			this.isLoading = true
			this.isSearch = true

			let columnsItem = this.columns.find(f => f.click);
			this.apiMethods(
				{ columnName: columnsItem.name, orderOperation: columnsItem.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true },
				this.clickFilter !== 0 ? this.clickFilter : null
			)
		},
		apiMethods(orderByColumn, OffSetFetch, status) {
			let obj = {
				"name": this.searchName,
				"stageId": [],
				"statusId": status ? [status] : [31,32,34],
				"typeId": [],
				"serviceTypeId": this.serviceTypes,
				"mkbId": this.searchResult.map(m=>m.id),
				"expertiseResultId": [],
				"npsDecisionId": this.searchResultNpc,
				"developerId": this.searchResultDev,
				"startDate": this.dateFrom,
				"finalDate": this.dateTo,
				"mkbCodesId": this.searchResultMkb.map(m=>m.id),
				"ageGroupId": this.ageGroups,
				"developerCompanyId": [],
				"orderByColumn": orderByColumn ? orderByColumn : undefined,
				"OffSetFetch": OffSetFetch ? OffSetFetch : undefined
			}

			this.api.post(`${this.apiUrlPortal}Projects`, obj)
			.then(res => {
				this.isLoading = false

				res.data.forEach(el => {
					el.serviceTypeId = el.serviceType ? el.serviceType.id : ''
					el.mkbName = el.mkb ? el.mkb.name : ''
					el.mkbCodesOne = el.mkbCodes.length > 0 ? el.mkbCodes[0].code : ''
					el.developerName = el.developer ? el.developer.name : ''
					el.statusName = el.status ? el.status.name : ''
					el.npcName = el.npsDecision ? el.npsDecision.name : ''
				})

				this.allStrokeDashboard = +res.headers['x-total-count']
				this.allStroke = res.headers[`${this.isSearch ? 'x-filtertotal-count' : 'x-total-count'}`]
				this.pageCount(+res.headers[`${this.isSearch ? 'x-filtertotal-count' : 'x-total-count'}`])
				this.list = orderBy(res.data, 'newFlag', 'desc')

				this.isSearch = false
			})
			.catch(error => {
				console.log(error);
				this.isLoading = false
			})
		},
		// downloadReports() {
		// 	let num = [];
		// 	if(this.filter.find(f=>f.id=='number').value !== null) this.filter.find(f=>f.id=='number').value.split(',').forEach(el => { num.push(+el) })

		// 	this.api.post(`${this.apiUrl}api/Projects/Report`, {
		// 		number: num.length > 0 ? num : undefined,
		// 		statusId: this.statuses.length > 0 ? this.statuses : undefined,
		// 		serviceTypeId: this.serviceTypes.length > 0 ? this.serviceTypes : undefined,
		// 		mkbId: this.searchResult.length > 0 ? this.searchResult.map(m=>m.id) : undefined,
		// 		npsDecisionId: this.searchResultNpc.length > 0?this.searchResultNpc:undefined,
		// 		startDate: this.dateFrom?this.dateFrom:undefined,
		// 		finalDate: this.dateTo?this.dateTo:undefined,
		// 		mkbCodesId: this.searchResultMkb.length > 0?this.searchResultMkb.map(m=>m.id):undefined,
		// 		ageGroupId: this.ageGroups.length > 0?this.ageGroups:undefined,
		// 		developerId: this.searchResultDev.length > 0?this.searchResultDev:undefined
		// 	}, { responseType: 'blob' })
		// 	.then((response) => {
		// 		const url = window.URL.createObjectURL(new Blob([response.data]));
		// 		const link = document.createElement('a');
		// 		link.href = url;
		// 		link.setAttribute('download', 'Отчет.xlsx');
		// 		document.body.appendChild(link);
		// 		link.click();
		// 	})
		// 	.catch((error) => console.log(error))
		// },
		filterStage(status) {
			this.isSearch = true
			this.isLoading = true
			this.pageNumber = 0
			this.clickFilter = status;

			if(status !== 0){
				this.apiMethods(null, {offsetRowCount: 0, fetchRowCount:this.size, use: true}, status)
			} else {
				this.apiMethods(null, {offsetRowCount: 0, fetchRowCount:this.size, use: true}, null)
			}
		},
		nextPageEV(id) {
			this.$router.push(`/viewCR/${id}`)
		},
		updateListEvent(size) {
			this.pageNumber = 0

			let columnsItem = this.columns.find(f => f.click);
			this.apiMethods(
				{ columnName: columnsItem.name, orderOperation: columnsItem.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true },
				this.clickFilter !== 0 ? this.clickFilter : null
			)
		},
		reset() {
			this.isLoading = true
			this.isSearch = false

			this.dateFrom = null
			this.dateTo = null
			this.searchName = null
			this.searchResult = []
			this.searchResultMkb = []
			this.ageGroups = []
			this.searchResultDev = []
			this.apiMethods(null, {offsetRowCount: 0, fetchRowCount:this.size, use: true})
		},
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			// console.log(this.maxYesterday);
			return local.toISOString().substr(0,10);
		}
	},
	mounted() {
		this.observer = new IntersectionObserver(this.infiniteScroll);
		this.observerMkb = new IntersectionObserver(this.infiniteScrollMkb);
	},
	created() {
		this.apiMethods(null, {offsetRowCount: 0, fetchRowCount:this.size, use: true})
		this.apiAll({type: 'get', url: `api/MKB`, id: undefined}).then(res => { this.mkbList = res.data })
		this.apiAll({type: 'get', url: `api/Companies`, id: undefined}).then(res => { this.сompaniesList = res.data })
		this.apiAll({type: 'get', url: `api/AgeGroups`, id: undefined}).then(res => { this.ageGroupsList = res.data })
	}
}
</script>

<style>
@import "../../style/stylePages.css";
@import "../../views/ListCr/style/mobile.css";

.ring-load {
	position: absolute;
	cursor: progress;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* opacity: 0.4; */
	z-index: 10;
	background-color: rgba(88, 88, 88, 0.2);
}

.ring-load-loading {
	left: 42%;
    top: 42%;
}

table tbody tr:hover {
	background: #f1f1f1;
	box-shadow: 0 1.5px 6px #00000080;
	cursor: pointer;
}

</style>
